import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { loggerService, networkService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store/auth";
import Backdrop from "../shared/components/backdrop.component";
import FieldError from "../shared/components/field-error.component";
import { isWithin24Hours } from "../shared/utils/helper.util";

const EditBookingCancel: React.FC = () => {
  const [consumer, setConsumer] = useConsumerStore((state) => [
    state.consumer,
    state.setConsumer,
  ]);
  const reasonSchema = Yup.object().shape({
    reasons: Yup.array().min(1, "Please select at least one option."),
    otherReason: Yup.string().when("reasons", {
      is: (reasons) => reasons.includes("Others"),
      then: Yup.string().required("Please enter other reasons"),
    }),
  });

  const { user } = useAuth0();
  const [appointmentData, setAppointmentData] = useState<any>(null);

  const authenticatedUser = {
    externalUserId: consumer.id,
    authenticationId: user?.sub,
  };

  const navigate = useNavigate();

  const getAppointmentDetails = () => {
    const appointmentToken: string = window.location.pathname.split("/")[2];
    networkService
      .get<any>(
        `/api/scheduling/patients/${consumer.id}/appointments/${appointmentToken}`
      )
      .then((res: any) => {
        setAppointmentData(res);
        websocketLoggerService.sendMessage({
          ...authenticatedUser,
          eventType: `API request: Appointment details by token: GET /api/scheduling/patients/${consumer.id}/appointments/${appointmentToken}`,
          eventSubType: `Get appointment details by token for cancelling appointment`,
          eventData: "fetched user's appointment successfully",
        });
      })
      .catch((err) => {
        websocketLoggerService.sendMessage({
          ...authenticatedUser,
          eventType: `API request: getAddresses: GET /api/scheduling/patients/${consumer.id}/appointments/${appointmentToken}`,
          eventSubType: `Get appointment details by token`,
          eventData: `could not fetch user's appointment, Error: ${JSON.stringify(
            err
          )}`,
        });
      });
  };

  useEffect(() => {
    getAppointmentDetails();
  }, []);

  useEffect(() => {
    // get timezone from browser
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const patientInfoWithTimezone = {
      ...consumer,
      timeZone: timezone,
    };

    setConsumer(patientInfoWithTimezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setConsumer]);

  const handleCancel = (values: any) => {
    networkService
      .delete(`/api/scheduling/patients/${consumer.id}/appointments`, {
        ...appointmentData,
        timeZone: consumer?.timeZone,
        cancellationReason:
          values.reasons[0] === "Others"
            ? values.otherReason
            : values.reasons.join(", "),
      })
      .then((res: any) => {
        // add the cancellation reason to excel sheet
        websocketLoggerService.sendMessage({
          ...authenticatedUser,
          eventType: `API request: cancel appointment: DELETE /api/scheduling/patients/${consumer.id}/appointments`,
          eventSubType: `Cancel appointment`,
          eventData: "user's appointment canceled successfully",
        });

        const valuesToLog = [
          // res.id,
          // res.appointmentTypeId,
          res.patientId,
          res.date,
          res.firstName,
          res.lastName,
          res.email,
          res.startTime,
          res.endTime,
          // res.startDateTime,
          // res.endDateTime,
          // res.token,
          res.note,
          res.status,
          // res.externalId,
          // res.appointmentType.id,
          res.appointmentType.name,
          res.appointmentType.description,
          // res.appointmentType.schedulerSystemUserId,
          res.appointmentType.durationInMinutes,
          res.appointmentType.locationType,
          // res.appointmentType.externalId,
          // res.appointmentType.userId,
          // res.participants[0].id,
          res.participants[0].firstName,
          res.participants[0].lastName,
          res.participants[0].email,
          values.reasons[0] === "Others"
            ? values.otherReason
            : values.reasons.join(", "),
        ];

        navigate("/cancel-appointment-success", {
          state: {
            valuesToLog,
          },
        });
      })
      .catch((err) => {
        loggerService.error(err);
        websocketLoggerService.sendMessage({
          ...authenticatedUser,
          eventType: `API request: cancel appointment: DELETE /api/scheduling/patients/${consumer.id}/appointments`,
          eventSubType: `Cancel appointment`,
          eventData:
            "Could not cancel user's appointment, Error: " +
            JSON.stringify(err),
        });
      });
  };

  if (!appointmentData) {
    return null;
  }

  return (
    <Backdrop>
      <div className="p-10 px-20 max-lg:p-2 max-w-3xl">
        <h1 className="text-3xl font-bold text-[#1A1A1A] mb-5 max-lg:text-2xl">
          Cancel Introductory Coaching Session
        </h1>
        {isWithin24Hours(appointmentData?.startDateTime) && (
          <div className="bg-red-50 border-l-4 border-red-600 text-yellow-700 p-4 mb-5 max-lg:text-sm">
            <p className="font-bold">Please note:</p>
            <p>
              Appointments cannot be cancelled within 24 hours of the scheduled
              session start time.
            </p>
            <p>
              If you need to make any changes or have any questions, please feel
              free to reach out to the Ripple team at{" "}
              <a
                href="mailto:rippleadmin@sheareshealthcare.com.sg"
                className="text-primary-blue">
                rippleadmin@sheareshealthcare.com.sg
              </a>
            </p>
          </div>
        )}
        <div className="border border-primary-800 bg-white w-full rounded-md ">
          <div className="flex items-center p-4 gap-6  rounded-t-md">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_95_2337)">
                <path
                  d="M6.382 6.13109C7.97575 4.85415 9.95781 4.15983 12 4.16309C14.125 4.16309 16.078 4.89909 17.618 6.13109L19.071 4.67809L20.485 6.09209L19.032 7.54509C20.0897 8.86923 20.7521 10.4651 20.9428 12.1491C21.1336 13.833 20.845 15.5366 20.1103 17.0638C19.3756 18.591 18.2246 19.8797 16.7898 20.7817C15.355 21.6836 13.6947 22.1621 12 22.1621C10.3053 22.1621 8.64498 21.6836 7.2102 20.7817C5.77541 19.8797 4.62443 18.591 3.88973 17.0638C3.15502 15.5366 2.86644 13.833 3.0572 12.1491C3.24796 10.4651 3.9103 8.86923 4.96801 7.54509L3.51501 6.09309L4.92901 4.67909L6.382 6.13209V6.13109ZM12 20.1631C12.9193 20.1631 13.8295 19.982 14.6788 19.6302C15.5281 19.2785 16.2997 18.7628 16.9498 18.1128C17.5998 17.4628 18.1154 16.6911 18.4672 15.8419C18.8189 14.9926 19 14.0823 19 13.1631C19 12.2438 18.8189 11.3336 18.4672 10.4843C18.1154 9.63502 17.5998 8.86335 16.9498 8.21334C16.2997 7.56333 15.5281 7.04771 14.6788 6.69593C13.8295 6.34415 12.9193 6.16309 12 6.16309C10.1435 6.16309 8.36301 6.90058 7.05026 8.21334C5.7375 9.52609 5.00001 11.3066 5.00001 13.1631C5.00001 15.0196 5.7375 16.8001 7.05026 18.1128C8.36301 19.4256 10.1435 20.1631 12 20.1631V20.1631ZM13 12.1631H16L11 18.6631V14.1631H8.00001L13 7.65809V12.1631ZM8.00001 1.16309H16V3.16309H8.00001V1.16309Z"
                  fill="#2A27D5"
                />
              </g>
              <defs>
                <clipPath id="clip0_95_2337">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.163086)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              {" "}
              {moment(appointmentData.startDateTime).format("hh:mm A")} -
              {moment(appointmentData.endDateTime).format("hh:mm A")}
            </div>
          </div>
          <div className="flex items-center p-4 gap-6 border-t border-b border-b-primary-800 border-t-primary-800">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_95_2338)">
                <path
                  d="M9 1.16309V3.16309H15V1.16309H17V3.16309H21C21.2652 3.16309 21.5196 3.26844 21.7071 3.45598C21.8946 3.64352 22 3.89787 22 4.16309V20.1631C22 20.4283 21.8946 20.6827 21.7071 20.8702C21.5196 21.0577 21.2652 21.1631 21 21.1631H3C2.73478 21.1631 2.48043 21.0577 2.29289 20.8702C2.10536 20.6827 2 20.4283 2 20.1631V4.16309C2 3.89787 2.10536 3.64352 2.29289 3.45598C2.48043 3.26844 2.73478 3.16309 3 3.16309H7V1.16309H9ZM20 10.1631H4V19.1631H20V10.1631ZM15.036 11.2991L16.45 12.7131L11.5 17.6631L7.964 14.1271L9.38 12.7131L11.501 14.8351L15.037 11.2991H15.036ZM7 5.16309H4V8.16309H20V5.16309H17V6.16309H15V5.16309H9V6.16309H7V5.16309Z"
                  fill="#2A27D5"
                />
              </g>
              <defs>
                <clipPath id="clip0_95_2338">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.163086)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div>
              {moment(appointmentData.startDateTime).format(
                "dddd DD MMMM YYYY"
              )}
            </div>
          </div>
          <div className="flex items-center p-4 gap-6 rounded-b-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                fill="rgba(42,39,213,1)"
              />
            </svg>

            <div className="flex flex-col">
              <span> {appointmentData.appointmentType.description}</span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            reasons: [],
            otherReason: "",
          }}
          onSubmit={(values) => {
            handleCancel(values);
          }}
          validationSchema={reasonSchema}>
          {({ values }) => (
            <Form>
              <div className="mt-8">
                In order for us to improve our service, please let us know why
                you are canceling:*
              </div>
              <div className="flex gap-8 mt-4 max-lg:flex-col max-lg:gap-4">
                <div className="flex flex-col gap-4">
                  <label className="flex gap-4 items-center">
                    <Field
                      type="checkbox"
                      name="reasons"
                      value="Change in plans"
                      className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                    />
                    <div>Change in plans</div>
                  </label>
                  <label className="flex gap-4 items-center">
                    <Field
                      type="checkbox"
                      name="reasons"
                      value="Change of mind"
                      className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                    />
                    <div>Change of mind</div>
                  </label>
                </div>
                <div className="flex flex-col gap-4">
                  <label className="flex gap-4 items-center">
                    <Field
                      type="checkbox"
                      name="reasons"
                      value="Inconvenient location"
                      className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                    />
                    <div>Inconvenient location</div>
                  </label>
                  <label className="flex gap-4 items-center">
                    <Field
                      type="checkbox"
                      name="reasons"
                      value="Others"
                      className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                    />
                    <div>Others (please specify)</div>
                  </label>
                </div>
              </div>
              {values.reasons.includes("Others") && (
                <>
                  <label>
                    <Field
                      type="text"
                      className="w-full mt-4 py-3 px-2 border border-gray-300 rounded-md"
                      placeholder="Specify the reason"
                      name="otherReason"
                    />
                  </label>
                  <ErrorMessage name="otherReason">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </>
              )}
              <ErrorMessage name="reasons">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>

              <button
                type="submit"
                disabled={
                  !(values.reasons || values.otherReason) ||
                  isWithin24Hours(appointmentData?.startDateTime)
                }
                className={`flex mt-8 justify-center  items-center cursor-pointer bg-ripple-white text-white w-40 py-3 rounded font-bold disabled:opacity-50 disabled:cursor-not-allowed
                ${
                  values.reasons || values.otherReason
                    ? "bg-primary-500"
                    : "bg-ripple-gray-500"
                }
                `}>
                Cancel Booking
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Backdrop>
  );
};

export default EditBookingCancel;
